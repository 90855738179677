import styled from "styled-components";

export const StyledHeaderBar = styled.div`
  margin: 0;
  padding: 10px;
  height: auto;
  display: grid;
  grid-column: span 2;
  background-image: linear-gradient(
    95deg,
    rgba(0, 0, 0, 1) 3%,
    rgba(45, 44, 44, 1) 86%
  );

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    grid-column: span 1
  }
`;


export const Header = styled.h1`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 13px;
  }
`;

export const Span = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    font-size: 13px;
    line-height: 1.5
  }
`;

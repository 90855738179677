import { Tile } from "../../common/Tile";
import { getConceptsList } from "../../utils/getApi";
import { useDataFetching } from "../../utils/useDataFetching";
import { compareObjects } from "../../utils/compareObjects";


export const Concepts = () => {
  const { data: concepts } = useDataFetching(getConceptsList);

  console.log('Przed sortowaniem:', concepts);

  const sortedConcepts = concepts ? concepts.result.sort(compareObjects) : [];

  console.log('Po sortowaniu:', sortedConcepts);

  return !concepts ? "" : <Tile list={concepts.result.sort(compareObjects)} concepts tileHeader={"Koncepcje"} />
    ;

};

import React, { useState } from "react";
import {
  List,
  Link,
  ListItem,
  ListItemContent,
  ListItemDescription,
  StyledTile,
  StyledVectorDown,
  StyledVectorUp,
  ExeVersion,
  TileHeader,
  NewConcept,
  DetailedItemInformation
} from "./styled";
import { InterestLevelBar } from "./InterestLevelBar";
import { checkDaysSincePublication } from "../../utils/checkDaysSincePublication";

export const Tile = ({ list, tileHeader, changeLog }) => {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <StyledTile>
      <TileHeader>{tileHeader}</TileHeader>
      {changeLog ? (
        <List>
          {list.flatMap((list) => {
            const items = [
              ...list.features,
              ...list.bugfixs,
              ...list.others,
            ];

            return items.map((item) => {
              return (
                <ListItem key={item.id} changeLog={changeLog}>
                  <ExeVersion changeLog>{`[${list.pamBuildVersion}]`}</ExeVersion>
                  <ListItemDescription dangerouslySetInnerHTML={{ __html: item.description }}/>
                  {item.link ? <Link href={item.link} target="_blank">Zobacz szczegóły</Link> : null} 
                </ListItem>
              )
            })
          })}
        </List>
      ) : (
        <List>
          {list.map((item, index) => (
            <ListItem key={item.id}>
              {openItem === index ? <StyledVectorUp onClick={() => toggleItem(index)} /> : <StyledVectorDown onClick={() => toggleItem(index)} />}         
              <ListItemDescription>
                {item.title}
                {checkDaysSincePublication(item.createdDateFromTable) === true ? <NewConcept>Nowość  </NewConcept> : ''}
              </ListItemDescription>
              <DetailedItemInformation>
                {item.levelOfInterest ? <InterestLevelBar level={item.levelOfInterest}/> : ""}   
                <Link href={item.link} target="_blank">Zobacz szczegóły</Link>
              </DetailedItemInformation>
              {openItem === index ? <ListItemContent dangerouslySetInnerHTML={{ __html: item.shortContent }}></ListItemContent> : ""}
            </ListItem>
          ))}
        </List>
      )}
    </StyledTile>
  )
};


import styled from "styled-components";

export const Container = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100vh;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.alabaster};

  @media (max-width: ${({ theme }) => theme.breakPoints.tabletMax}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const API_URL = "https://pamwebapi.e-pam.pl/api"
//const API_URL = "http://192.168.0.110:7249/api"
//const API_URL = "http://136.243.133.115:8989/api"

const UrlApiChangeLogs = `${API_URL}/paminfo/GetChangelogs`
const UrlApiCurrentFeatures = `${API_URL}/PamInfo/GetFeatures`
const UrlApiConceptsList = `${API_URL}/PamInfo/GetIdeas`;


const fetchData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Błąd podczas pobierania danych: ${response.statusText}`);
  }
  return await response.json();
};

export const getChangeLogs = async () => {
  return fetchData(UrlApiChangeLogs);
};

export const getConceptsList = async () => {
  return fetchData(UrlApiConceptsList);
};

export const getCurrentFeatures = async () => {
  return fetchData(UrlApiCurrentFeatures);
};
